import LocalizedStrings from 'react-localization'

const strings = new LocalizedStrings({
  en: {
    enrollment: {
      submit: {
        licenseNumber: 'License Number',
        licenseNumberPrefix: '○○知事(X)第',
        licenseNumberSuffix: '号',
        searchOffice: 'Search Office by License Number',
        send: 'Next'
      }
    },
    client: {
      edit: {
        override_confirmation: '"{0}"\nOverride?'
      }
    },
    property_search_component: {
      keyword: {
        keyword: 'Property Name, Address'
      },
      station: {
        line: 'Line Name',
        station: 'Station Name',
        distanceInMinute: 'Distance in Minute',
        min: 'Min'
      },
      rent: {
        label: 'Monthly Rent (with CAM)',
        man: 'man',
        yen: 'yen',
        from: {
          man: 'From (man-yen)',
          sen: 'From (less than man-yen)'
        },
        to: {
          man: 'To (man-yen)',
          sen: 'To (less than man-yen)'
        }
      },
      area: {
        label: 'Area (sqm)',
        from: 'From',
        to: 'To',
        sqm: 'sqm'
      },
      madori: {
        numberOfRooms: '# of rooms',
        noteSymbol: '*',
        serviceRoom: 'S (Service room) required',
        madori: 'Plan'
      },
      others: {
        ari: 'Ari',
        ad: 'AD',
        immediatelyAvailable: 'Immediately Available',
        noApplication: 'No Application',
        year: '',
        builtYear: 'Built Year',
        moreThan1: '-',
        moreThan2: '-'
      },
      search: 'Search',
      result: {
        results: 'Results {1} to {2} displayed out of {0}',
        builtDate: 'Built: {1}/{0}',
        kaidate: '{0} Floors',
        notFound: 'Not Found'
      },
      summary: {
        unknownName: 'Unknown',
        unknownAddress: 'Unknown',
        unknownBuiltDate: 'Unknown',
        unknownStructure: 'Unknown',
        unknownFloors: 'Unknown',
        unknownAccess: 'Unknown',
        rent: 'Rent:{0} yen',
        cam: '(CAM:{0} yen included)',
        unknownRent: 'Unknown',
        unknown: 'Unknown',
        none: 'None',
        floor: 'Floor',
        floorValue: '{0}',
        madori: 'Plan',
        area: 'Area',
        sqm: '{0}㎡',
        movableDate: 'Movable',
        immediately: 'Immediately',
        months: '{0} months',
        fixedCost: 'Fixed Cost',
        deposit: 'Deposit',
        kyakuzuke: 'Kyakuzuke',
        karinushi: 'Karinushi',
        percent: '{0}%',
        ad: 'AD'
      }
    },
    footer: {
      copyright: 'ChinTai boost',
      terms: 'Terms',
      policy: 'Policy'
    }
  },
  ja: {
    enrollment: {
      submit: {
        licenseNumber: '免許番号',
        licenseNumberPrefix: '○○知事(X)第',
        licenseNumberSuffix: '号',
        searchOffice: '免許番号から事務所を探す',
        send: '次のステップへ',
      }
    },
    client: {
      edit: {
        override_confirmation: 'この郵便番号の住所は「{0}」です。\n下の住所欄を上書きしますか？ '
      }
    },
    property_search_component: {
      keyword: {
        keyword: '物件名、住所'
      },
      station: {
        line: '路線名',
        station: '駅名',
        distanceInMinute: '徒歩(分)',
        min: '分'
      },
      rent: {
        label: '月額賃料（共益費込み）',
        man: '万',
        yen: '円',
        from: {
          man: '賃料の下限（万）',
          sen: '賃料の下限（千）'
        },
        to: {
          man: '賃料の上限（万）',
          sen: '賃料の上限（千）'
        }
      },
      area: {
        label: '専有面積（㎡）',
        from: '専有面積の下限',
        to: '専有面積の上限',
        sqm: '㎡'
      },
      madori: {
        numberOfRooms: '部屋数',
        noteSymbol: '※',
        serviceRoom: 'S（サービスルーム）必須',
        madori: '間取り'
      },
      others: {
        ari: 'あり',
        ad: 'AD',
        immediatelyAvailable: '即時入居可',
        noApplication: '申し込みなし',
        year: '年',
        builtYear: '築年',
        moreThan1: '以降',
        moreThan2: '以上'
      },
      search: '検索する',
      result: {
        results: '{0}件中 {1}〜{2}件目',
        builtDate: '{0}年{1}月築',
        kaidate: '地上{0}階建',
        notFound: 'この条件の募集はありません'
      },
      summary: {
        unknownName: '物件名不明',
        unknownAddress: '住居表示不明',
        unknownBuiltDate: '築年不明',
        unknownStructure: '構造不明',
        unknownFloors: '階建不明',
        unknownAccess: '最寄り駅不明',
        rent: '賃料:{0}円',
        cam: '（共益費 {0}円 含む）',
        unknownRent: '賃料不明',
        unknown: '不明',
        none: 'なし',
        floor: '所在階',
        floorValue: '{0}階',
        madori: '間取り',
        area: '専有面積',
        sqm: '{0}㎡',
        movableDate: '入居可能日',
        immediately: '即時',
        months: '{0}ヶ月',
        fixedCost: '礼金+敷引',
        deposit: '敷金+保証金',
        kyakuzuke: '客付',
        karinushi: '借主',
        percent: '{0}%',
        ad: 'AD'
      }
    },
    footer: {
      copyright: '賃貸 boost',
      terms: '利用規約',
      policy: 'プライバシーポリシー'
    }
  }
})

export default strings

export const f = (str: string, ...values: Array<any>) => {
  return strings.formatString(str, ...values as any).toString()
}
