import React from 'react'
import _debounce from 'lodash/debounce'
import { httpGet } from '../../../../utils'
import AutocompleteTextField from './autocomplete_text_field'
import {Chip, Grid, InputAdornment, Slider, Typography} from '@material-ui/core'
import {GridProps} from '@material-ui/core/Grid'
import {GetTagProps} from '@material-ui/lab/Autocomplete'
import {FieldDescriptor} from '@shopify/react-form-state'

interface LineResult {
  id: number
  name: string
}

interface StationResult {
  id: number
  name: string
}

function normalizeValue(v?: string[]) : string[] | undefined {
  return v?.filter(v => v)
}

const generateCommonProps = (inputName: string, label: string, iconName: string) => {
  return {
    multiple: true,
    filterSelectedOptions: true,
    renderOption: (option: string) => (<div>{option}</div>),
    renderTags: (value: string[], getTagProps: GetTagProps) =>
      value.map((option: string, index: number) => (
        <>
          <input type="hidden" name={inputName} value={option}/>
          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
        </>
      )),
    textFieldProps: {
      label: label,
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <i className="material-icons">{iconName}</i>
          </InputAdornment>
        )
      },
      onKeyDown: (event: React.KeyboardEvent): void => {
        if (event.key === 'Enter') {
          event.preventDefault()
        }
      }
    }
  }
}

type StationFieldProps = {
  label: string
  descriptor: FieldDescriptor<string[]>
  lines: string[]
}

const StationField = React.memo((props: StationFieldProps) => {
  const { label, descriptor, lines } = props

  const fetchStations = (setter: (newOptions: any[]) => void, inputValue?: string) => {
    _debounce(async (word) => {
      let query = `station=${word || ''}`
      lines.forEach(l => query += `&line[]=${l}`)

      const stations: StationResult[] = await httpGet(`/api/stations.json?${query}`)
      setter(normalizeValue(stations.map(s => s.name)) || [])
    }, 300)(inputValue)
  }

  return (
    <AutocompleteTextField
      name="station[]"
      descriptor={descriptor}
      setOptionsOn={lines.length > 0 ? 'init' : 'input'}
      setOptions={fetchStations}
      propsForResetOptions={lines}
      {...generateCommonProps('station[]', label, 'traffic')}
      value={normalizeValue(descriptor.value)}
    />
  )
}, (prevProps, nextProps) => prevProps.descriptor === nextProps.descriptor && prevProps.lines === nextProps.lines)

type LineFieldProps = {
  label: string
  descriptor: FieldDescriptor<string[]>
}

const LineField = React.memo(({descriptor, label}: LineFieldProps) => {
  const fetchLines = (setter: (newOptions: any[]) => void) => {
    _debounce(async () => {
      const result: LineResult[] = await httpGet('/api/lines.json?line[]=')
      const normalized = normalizeValue(result.map(l => l.name))
      setter(normalized || [])
    }, 300)()
  }

  return (
    <AutocompleteTextField
      name="line[]"
      descriptor={descriptor}
      setOptionsOn="init"
      setOptions={fetchLines}
      {...generateCommonProps('line[]', label, 'train')}
      value={normalizeValue(descriptor.value)}
    />
  )
}, (prevProps, nextProps) => prevProps.descriptor === nextProps.descriptor)

type StationProps = {
  lineDescriptor: FieldDescriptor<string[]>
  stationDescriptor: FieldDescriptor<string[]>
  distanceInMinuteDescriptor: FieldDescriptor<number>
  labels: {
    line: string
    station: string
    distanceInMinute: string
    min: string
  },
  narrow?: boolean
}

export default React.memo(function LineStationDisplay(props: StationProps) {
  const { narrow } = props
  const itemParams: GridProps = { xs: 12, sm: narrow ? 12 : 6, md: narrow ? 12 : 4, lg: narrow ? 12 : 3 }
  const distanceInMinuteMarks = [1, 3, 5, 10, 15, 30].map(n => ({ value: n, label: n}))

  return (
    <Grid container spacing={2}>
      <Grid item {...itemParams}>
        <LineField
          descriptor={props.lineDescriptor}
          label={props.labels.line}/>
      </Grid>
      <Grid item {...itemParams}>
        <StationField descriptor={props.stationDescriptor}
                      lines={props.lineDescriptor.value}
                      label={props.labels.station}/>
      </Grid>
      <Grid item {...itemParams}>
        <Typography id="distance-in-minute" gutterBottom>
          {props.labels.distanceInMinute}
        </Typography>
        <Slider
          name="distance_in_minute"
          value={props.distanceInMinuteDescriptor?.value}
          defaultValue={props.distanceInMinuteDescriptor?.initialValue}
          onChange={(event, value) => props.distanceInMinuteDescriptor?.onChange(value as number)}
          onBlur={_ => props.distanceInMinuteDescriptor?.onBlur()}
          valueLabelFormat={value => `${value}${props.labels.min}`}
          getAriaValueText={value => `${value}${props.labels.min}`}
          aria-labelledby="distance-in-minute"
          step={null}
          valueLabelDisplay="on"
          min={1}
          max={30}
          marks={distanceInMinuteMarks}
        />
      </Grid>
    </Grid>
  )
}, ((prevProps, nextProps) => {
  return prevProps.lineDescriptor === nextProps.lineDescriptor
    && prevProps.stationDescriptor === nextProps.stationDescriptor
    && prevProps.distanceInMinuteDescriptor === nextProps.distanceInMinuteDescriptor
    && prevProps.narrow === nextProps.narrow
}))
