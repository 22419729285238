import React, {useEffect, useState} from 'react'
import ReactDOM from 'react-dom'
import {createStyles, Theme, ThemeProvider} from '@material-ui/core'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import {PropertySearchContainer} from './property_search_container'
import {PropertySearchForm} from './property_search_components/forms/property_search_form'
import {PropertySearchResult} from './property_search_components/results/property_search_result'
import localeStrings from '../../locales/strings'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Hidden from '@material-ui/core/Hidden'
import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Fab from '@material-ui/core/Fab'
import {BrowserRouter, withRouter, RouteComponentProps} from 'react-router-dom'
import {fetchProperties} from './property_search_components/api'
import {HistoryState} from './search_params'

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#ff6434',
      main: '#dd2c00',
      dark: '#a30000',
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#bef67a',
      main: '#8bc34a',
      dark: '#5a9216',
      contrastText: '#000000'
    }
  }
})

declare global {
  interface Window {
    locale: string
    searchParams: string
  }
}

const drawerWidth = 300;

const useDrawerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
      paddingTop: 32,
      [theme.breakpoints.up('sm')]: {
        top: 64,
        height: 'calc(100% - 64px - 32px)'
      }
    },
    content: {
      flexGrow: 1,
      maxWidth: '100%',
      paddingTop: 64,
      position: 'fixed',
      left: drawerWidth,
      top: 64,
      width: `calc(100% - ${drawerWidth}px - 24px - 24px)`,
      height: 'calc(100% - 64px - 24px - 64px)',
      overflow: 'auto',
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(3),
      },
      [theme.breakpoints.down('xs')]: {
        boxSizing: 'border-box',
        top: 58,
        left: 0,
        width: '100%',
        height: 'calc(100% - 58px)',
        paddingTop: theme.spacing(5),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      }
    },
    fab: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    }
  }),
);

const generateInitialState = () => {
  const p = JSON.parse(window.searchParams)
  return {
    keyword: p.keyword || '',
    line: p.line || [] as string[],
    station: p.station || [] as string[],
    distance_in_minute: p.distance_in_minute ? parseInt(p.distance_in_minute) : 30,
    rent: {
      from: {
        man: p.rent?.from?.man || '',
        sen: p.rent?.from?.sen || ''
      },
      to: {
        man: p.rent?.to?.man || '',
        sen: p.rent?.to?.sen || ''
      }
    },
    area: {
      from: p.area?.from || '',
      to: p.area?.to || ''
    },
    madori: {
      one_room: p.madori?.one_room === 'on' || false,
      number_of_rooms: p.madori?.number_of_rooms || '1',
      number_of_rooms_to: p.madori?.number_of_rooms_to || '',
      k: p.madori?.k === 'on' || false,
      dk: p.madori?.dk === 'on' || false,
      lk: p.madori?.lk === 'on' || false,
      ldk: p.madori?.ldk === 'on' || false,
      s: p.madori?.s === 'on' || false
    },
    ad: p.ad || '0',
    immediately_available: p.immediately_available === 'on' || false,
    no_application: p.no_application === 'on' || false,
    built_year: p.built_year || '1983'
  }
}

const PropertySearchInner = withRouter(({history}: RouteComponentProps<{}, any, HistoryState>) => {
  localeStrings.setLanguage(window.locale)

  const classes = useDrawerStyles()
  const [mobileOpen, setMobileOpen] = useState(false)
  const {params, setResult, setParams, setLoading} = PropertySearchContainer.useContainer()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    const location = history.location
    history.replace(
      Object.assign({}, location, {state: {params: params, result: null}}))

    history.listen((location, action) => {
      if (action === 'POP') {
        setLoading(true)
        setParams(location.state.params)
        setTimeout(() => {
          setResult(location.state.result)
          setLoading(false)
        })
      }
    })

    if (location.pathname === '/properties') {
      const page = location?.state?.page
      setLoading(true)
      setTimeout(() => {
        fetchProperties(page).then((result) => {
          if (result) {
            setResult(result.properties)
            history.replace(
              Object.assign({}, location, {state: {params: params, result: result.properties, page: page}}))
          }
          setLoading(false)
        })
      })
    }
  }, [])

  const drawer = (
    <div>
      <Container maxWidth="xs">
        <PropertySearchForm narrow={true} setMobileOpen={setMobileOpen} />
      </Container>
    </div>
  );

  return (
    <div className={classes.root}>
      <nav className={classes.drawer}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <Typography variant="h3" style={{textAlign: 'center', marginBottom: 16}}><span style={{color: '#EC4335', fontSize: '.75em', fontWeight: 700, marginRight: '.17em'}}>賃貸</span><span style={{color: '#4587F6'}}>B</span><span style={{color: '#E94638'}}>o</span><span style={{color: '#FFC805'}}>o</span><span style={{color: '#38B659'}}>s</span><span style={{color: '#EC4335', transform: 'rotate(-10deg) translate(.03em)', display: 'inline-block'}}>t</span></Typography>
        <PropertySearchResult />
        <footer className="ctb-footer ctb-footer--show">
          <ul className="ctb-footer__items">
            <li className="ctb-footer__item ctb-footer__item--copyright">© 2020 {localeStrings.footer.copyright}</li>
            <li className="ctb-footer__item"><a href="/tos">{localeStrings.footer.terms}</a></li>
            <li className="ctb-footer__item"><a href="/privacy">{localeStrings.footer.policy}</a></li>
          </ul>
        </footer>
      </main>
      <Fab className={classes.fab} color="primary" onClick={handleDrawerToggle}>
        <i className="fas fa-search fa-lg"></i>
      </Fab>
    </div>
  )
})

const PropertySearch = withRouter(() => {
  return (
    <ThemeProvider theme={theme}>
      <PropertySearchContainer.Provider initialState={generateInitialState()}>
        <PropertySearchInner/>
      </PropertySearchContainer.Provider>
    </ThemeProvider>
  )
})

export default (): void => {
  document.addEventListener('DOMContentLoaded', () => {
    ReactDOM.render(<BrowserRouter><PropertySearch /></BrowserRouter>, document.getElementById('property_search_component'))
  })
}
