import React from 'react'
import {Checkbox, FormControlLabel, Grid, makeStyles, Theme, useTheme} from '@material-ui/core'
import {SelectableTextField} from './selectable_text_field'
import {FieldDescriptor} from '@shopify/react-form-state'

type StyleProps = {
  theme: Theme,
  narrow?: boolean
}

type NarrowStyles = {
  breakpoint: "xs" | "sm" | "md" | "lg" | "xl",
  styles: {}
}

const generateNarrowStyles = (styles: {}, narrowStyles: NarrowStyles[], props: StyleProps) => {
  if (props.narrow) {
    return Object.assign({}, styles, ...narrowStyles.map(ns => ns.styles))
  }
  return narrowStyles.reduce((result, ns) =>
    Object.assign({}, result, {
      [props.theme.breakpoints.down(ns.breakpoint)]: ns.styles
    }))
}

const useStyles = makeStyles({
  root: (props: StyleProps) =>
    generateNarrowStyles({
        flexDirection: 'column'
      },
      [
        {
          breakpoint: 'sm',
          styles: {
            flexDirection: 'row',
            flexWrap: 'nowrap'
          }
        },
        {
          breakpoint: 'xs',
          styles: {
            flexWrap: 'wrap'
          }
        }
      ],
      props),
  item: (props: StyleProps) =>
    generateNarrowStyles({
        width: 240
      },
      [
        {
          breakpoint: 'sm',
          styles: {
            flex: '1 1 100%',
            '&:last-child': {
              flex: '0 1 auto',
              whiteSpace: 'nowrap',
              '&>*': {
                marginRight: 0
              }
            }
          }
        },
        {
          breakpoint: 'xs',
          styles: {
            flex: '1 1 50%'
          }
        }
      ],
      props)
  }
)

type OthersProps = {
  adDescriptor: FieldDescriptor<string>
  immediatelyAvailableDescriptor: FieldDescriptor<boolean>
  noApplicationDescriptor: FieldDescriptor<boolean>
  builtYearDescriptor: FieldDescriptor<string>
  labels: {
    ad: string
    ari: string
    immediatelyAvailable: string
    noApplication: string
    year: string
    builtYear: string
    moreThan1: string
    moreThan2: string
  },
  narrow?: boolean
}

const yearOptions = Array.from(
  Array(new Date().getFullYear() - 1950 + 2),
  (_, k) => (k + 1950)).map(y => y.toString())
const adOptions = ['0', '50', '100', '150', '200', '250']

export default React.memo(function Others(props: OthersProps) {
  const { narrow, adDescriptor, builtYearDescriptor, immediatelyAvailableDescriptor, noApplicationDescriptor } = props

  const theme = useTheme()
  const classes = useStyles({theme, narrow})

  return (
    <Grid container className={classes.root} spacing={2} >
      <Grid item className={classes.item}>
        <SelectableTextField
          descriptor={builtYearDescriptor}
          type="number"
          options={yearOptions}
          label={props.labels.builtYear}
          suffix={`${props.labels.year}${props.labels.moreThan1}`}
          inputProps={{
            min: 1950,
            max: new Date().getFullYear() + 1,
            style: { textAlign: 'right' } }}/>
      </Grid>
      <Grid item className={classes.item}>
        <SelectableTextField
          descriptor={adDescriptor}
          type="number"
          options={adOptions}
          label={props.labels.ad}
          suffix={`%${props.labels.moreThan2}`}
          inputProps={{
            min: 0,
            max: 300,
            step: 50,
            style: { textAlign: 'right' } }}/>
      </Grid>
      <Grid item className={classes.item}>
        <FormControlLabel
          control={
            <Checkbox
              name={immediatelyAvailableDescriptor.name}
              value="on"
              checked={immediatelyAvailableDescriptor.value}
              onChange={(event, checked) => {
                immediatelyAvailableDescriptor.onChange(checked)
              }}
              onBlur={() => immediatelyAvailableDescriptor.onBlur()}
              id="immediately_available" />
          }
          label={props.labels.immediatelyAvailable}
          value="on"/>
      </Grid>
      <Grid item className={classes.item}>
        <FormControlLabel
          control={
            <Checkbox
              name={noApplicationDescriptor.name}
              value="on"
              checked={noApplicationDescriptor.value}
              onChange={(event, checked) => {
                noApplicationDescriptor.onChange(checked)
              }}
              onBlur={() => noApplicationDescriptor.onBlur()}
              id="no_application" />
          }
          label={props.labels.noApplication}
          value="on"/>
      </Grid>
    </Grid>
  )
}, (prevProps, nextProps) => {
  return prevProps.adDescriptor === nextProps.adDescriptor
    && prevProps.immediatelyAvailableDescriptor === nextProps.immediatelyAvailableDescriptor
    && prevProps.noApplicationDescriptor === nextProps.noApplicationDescriptor
    && prevProps.builtYearDescriptor === nextProps.builtYearDescriptor
    && prevProps.narrow === nextProps.narrow
})
