import { default as propertySearchComponent } from '../components/property_search_component'
import '../../styles/properties/index'

export default () => {
  propertySearchComponent()

  document.querySelectorAll('.table-row[data-href] > td').forEach(elm => {
    const parent = <HTMLElement>elm.parentNode
    if (parent === null) return

    (<HTMLElement>elm).style.position = 'relative'
    elm.innerHTML = `${elm.innerHTML}<a href="${parent.dataset.href}" style="position:absolute;top:0;left:0;width:100%;height:100%;"></a>`
  })

  const searchBox = document.querySelector<HTMLElement>('.search-box')
  if (searchBox) {
    setTimeout(() => searchBox.dataset.active = 'false', 10)
    Array.from(document.getElementsByClassName('search-box-toggle'))
      .forEach(function (toggle) {
        toggle.addEventListener('click', () => {
          searchBox.dataset.active = searchBox.dataset.active == 'true' ? 'false' : 'true';
        })
      })
  }
}
