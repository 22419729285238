import React, {useEffect, useState} from 'react'
import TextField, {TextFieldProps} from '@material-ui/core/TextField'
import Autocomplete, {AutocompleteProps} from '@material-ui/lab/Autocomplete'
import {CircularProgress} from '@material-ui/core'
import {FieldDescriptor} from '@shopify/react-form-state'

interface AutocompleteTextFieldProps extends Partial<AutocompleteProps> {
  setOptions: (setter: (newOptions: any[]) => void, inputValue?: string) => void
  textFieldProps?: TextFieldProps
  setOptionsOn: 'init' | 'input'
  propsForResetOptions?: any
  descriptor: FieldDescriptor<any>
  name?: string
}

export default function AutocompleteTextField(props: AutocompleteTextFieldProps): JSX.Element {
  let [inputValue, setInputValue] = useState('')
  let [options, setOptions] = useState<any[]>([])
  let [loading, setLoading] = useState(false)

  const {
    textFieldProps,
    setOptions: _,
    onInputChange,
    setOptionsOn,
    propsForResetOptions,
    descriptor,
    name,
    value,
    ...rest } = props

  const deps = (setOptionsOn === 'init')
    ? [null, setOptionsOn]
    : [inputValue, props.setOptions]
  if (propsForResetOptions) {
    deps.push(propsForResetOptions)
  }

  // The behavior of this effect is slightly changed by setOptionsOn variable
  useEffect(() => {
    let active = true

    if (setOptionsOn === 'input' && inputValue === '') {
      setOptions([])
      return undefined
    }

    setLoading(true)
    props.setOptions((results: any[]) => {
      if (active) {
        setOptions(results)
        setLoading(false)
      }
    }, setOptionsOn === 'input' ? inputValue : undefined)

    return () => {
      active = false
      setLoading(false)
    }
  }, deps)

  return (
    <Autocomplete
      {...rest as any}
      options={options}
      autoComplete
      includeInputInList
      onInputChange={(event, value) => {
        setInputValue(value)
        if (props.freeSolo) descriptor.onChange(value)
      }}
      onChange={(event, value) => {
        descriptor.onChange(value)
      }}
      value={value || descriptor.value}
      defaultValue={descriptor.initialValue}
      onBlur={_ => descriptor?.onBlur()}
      renderInput={params => {
        const inputProps = textFieldProps ? textFieldProps.InputProps : {}
        const startAdornment = inputProps ? inputProps.startAdornment : {}

        return (
          <TextField
            {...params}
            {...textFieldProps}
            name={name || descriptor.name}
            variant="outlined"
            fullWidth
            InputProps={{
              ...params.InputProps,
              ...inputProps,
              startAdornment: (
                <React.Fragment>
                  {startAdornment}
                  {params.InputProps.startAdornment}
                </React.Fragment>
              ),
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            } as any}
          />)}
      }
    />
  )
}
