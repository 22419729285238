import {PropertySearchContainer} from './../../property_search_container'
import localeStrings from '../../../../locales/strings'
import React, {Dispatch, SetStateAction, useMemo} from 'react'
import Grid from '@material-ui/core/Grid'
import Keyword from './keyword'
import Station from './station'
import Rent from './rent'
import Area from './area'
import Madori from './madori'
import Others from './others'
import Button from '@material-ui/core/Button'
import {RouteComponentProps, withRouter} from 'react-router-dom'
import FormState from '@shopify/react-form-state'
import {mapObject} from '@shopify/react-form-state/dist/src/utilities'
import {fetchProperties} from '../api'
import _debounce from 'lodash/debounce'

declare global {
  interface Window {
    locale: string
    searchParams: string
  }
}

type PropertySearchFormProps = RouteComponentProps<{}> & {
  narrow?: boolean,
  setMobileOpen: Dispatch<SetStateAction<boolean>>
}

export const PropertySearchForm = withRouter((props: PropertySearchFormProps) => {
  const {params, setResult, setLoading, setParams} = PropertySearchContainer.useContainer()
  const {narrow, setMobileOpen} = props

  const locales = localeStrings.property_search_component

  return useMemo(() =>
    <FormState
      initialValues={params}
      onSubmit={_debounce(async ({fields}) => {
        const params: any = mapObject(fields, ({value}) => value)

        setMobileOpen(false)
        setLoading(true)
        const result = await fetchProperties()
        if (result) {
          setParams(params)
          setResult(result.properties)
          props.history.push({
            pathname: '/properties',
            search: result.queryParams,
            state: {params: params, result: result.properties, page: 1}
          })
        }
        setLoading(false)
      },300)}
    >
      {formDetails => {
        const {fields, dirty, reset, submit, submitting, errors} = formDetails
        const {keyword, line, station, distance_in_minute, rent, area, madori, ad, immediately_available, no_application, built_year } = fields

        return (
          <form id="search-form" onSubmit={submit} style={{marginBottom: 24}}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Keyword
                  descriptor={keyword}
                  labels={locales.keyword}/>
              </Grid>
              <Grid item xs={12}>
                <Station
                  lineDescriptor={line}
                  stationDescriptor={station}
                  distanceInMinuteDescriptor={distance_in_minute}
                  labels={locales.station}
                  narrow={narrow}/>
              </Grid>
              <Grid item xs={12} md={narrow ? 12 : 8} lg={narrow ? 12 : 6}>
                <Rent
                  descriptor={rent}
                  strings={locales.rent}
                  narrow={narrow}/>
              </Grid>
              <Grid item xs={12} md={narrow ? 12 : 4} lg={narrow ? 12 : 4}>
                <Area
                  descriptor={area}
                  strings={locales.area}/>
              </Grid>
              <Grid item xs={12} md={narrow ? 12 : 8} lg={narrow? 12 : 6}>
                <Madori
                  descriptor={madori}
                  labels={locales.madori}
                  narrow={narrow}/>
              </Grid>
              <Grid item xs={12} md={narrow ? 12 : 4} lg={narrow ? 12 : 6}>
                <Others
                  adDescriptor={ad}
                  immediatelyAvailableDescriptor={immediately_available}
                  noApplicationDescriptor={no_application}
                  builtYearDescriptor={built_year}
                  labels={locales.others}
                  narrow={narrow}/>
              </Grid>
              <Grid item xs={12} style={{textAlign: 'center'}}>
                <Button type="submit"
                        variant="contained"
                        color="primary"
                        disabled={submitting}
                        startIcon={<i className="material-icons mdc-button__icon" aria-hidden="true">search</i>}
                >
                  {locales.search}
                </Button>
              </Grid>
            </Grid>
          </form>
        )
      }}
    </FormState>
  , [params, narrow, locales])
})

