import Typography from '@material-ui/core/Typography'
import React from 'react'
import {PropertySearchContainer} from './../../property_search_container'
import Grid from '@material-ui/core/Grid'
import {PropertySummary, PropertySummaryProps} from './property_summary'
import {Unit} from '../../search_result'
import IconButton from '@material-ui/core/IconButton'
import strings, {f} from '../../../../locales/strings'
import Skeleton from '@material-ui/lab/Skeleton'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import {fetchProperties} from '../api'
import _debounce from 'lodash/debounce'

type PropertySearchCountParams = {
  total_count: number
  limit_value: number
  total_pages: number
  current_page: number
  prev_page?: number
  next_page?: number
  out_of_range: boolean
}

interface PaginationProps extends PropertySearchCountParams {
  onItemClick?(pageNumber: number): void
}

const Pagination = (props: PaginationProps) => {
  const {current_page, prev_page, next_page, total_pages} = props

  if (total_pages === 0) return null

  const buttonClicked = (pageNumber: number) => (() => props.onItemClick && props.onItemClick(pageNumber))

  return (
    <Grid container alignItems="center">
      <Grid item><IconButton disabled={current_page === 1} onClick={buttonClicked(1)}><i
        className="fas fa-step-backward fa-xs"></i></IconButton></Grid>
      <Grid item><IconButton disabled={current_page === 1} style={{paddingTop: 8, paddingBottom: 8, paddingRight: 16}}
                             onClick={buttonClicked(prev_page || 1)}><i className="fas fa-caret-left"></i></IconButton></Grid>
      <Grid item style={{paddingLeft: 12, paddingRight: 12}}>
        <Typography variant="h5" component="div">{current_page}<span
          style={{fontSize: '.6em'}}>/{total_pages}</span></Typography>
      </Grid>
      <Grid item><IconButton disabled={current_page === total_pages}
                             style={{paddingTop: 8, paddingBottom: 8, paddingLeft: 16}}
                             onClick={buttonClicked(next_page || total_pages)}><i
        className="fas fa-caret-right"></i></IconButton></Grid>
      <Grid item><IconButton disabled={current_page === total_pages} onClick={buttonClicked(total_pages)}><i
        className="fas fa-step-forward fa-xs"></i></IconButton></Grid>
    </Grid>
  )
}

const PropertySearchCount = (props: PropertySearchCountParams) => {
  const locales = strings.property_search_component.result;

  const {total_count, current_page, limit_value} = props
  const start = (current_page - 1) * limit_value
  let end = start + limit_value
  if (end > total_count) end = total_count

  return (
    <Typography variant="body1">{f(locales.results, total_count, start + 1, end)}</Typography>
  )
}

export const PropertySearchResult = withRouter((props: RouteComponentProps<{}>) => {
  const locales = strings.property_search_component.result;
  const {loading, result, setLoading, setParams, setResult, params} = PropertySearchContainer.useContainer()
  let properties: JSX.Element[] = []
  let topRef = React.createRef<HTMLDivElement>()

  if (loading) {
    setTimeout(() => window.scrollTo({top: 0}), 1)
    properties = Array(16).fill(0).map((_, index) => (
      <Grid item xs={12} md={6} lg={4} xl={3} key={`loading-unit-${index}`}><PropertySummary loading={true}/></Grid>
    ))
  } else if (result) {
    properties = result.units.map((unit: Unit) => (
      <Grid item xs={12} md={6} lg={4} xl={3} key={`unit-${unit.id}`}><PropertySummary {...toPropertySummaryProps(unit)}/></Grid>
    ))
  }

  const paginationClicked = (pageNumber: number) => {
    _debounce(async () => {
      setLoading(true)
      const result = await fetchProperties(pageNumber)
      if (result) {
        setParams(params)
        setResult(result.properties)
        props.history.push({
          pathname: '/properties',
          search: result.queryParams,
          state: {params: params, result: result.properties, page: pageNumber}
        })
      }
      setLoading(false)
    }, 300)()
  }

  const pagination = (
    <Grid item>
      {(loading || !result) ? (
        <Skeleton height={10} width={240} style={{marginBottom: 20, marginTop: 14}}/>
      ) : (
        <Pagination {...result} onItemClick={paginationClicked}/>
      )}
    </Grid>
  )
  const searchCount = (
    <Grid item>
      {(loading || !result) ? (
        <Skeleton height={10} width={200} style={{marginBottom: 20}}/>
      ) : (
        <PropertySearchCount {...result} />
      )}
    </Grid>
  )

  return (
    <div ref={topRef}>
      <Grid container direction="column" alignItems="center" style={{marginBottom: 12}}>
        {(result && result.total_count === 0) && (
          <Typography variant="body1">{locales.notFound}</Typography>
        )}
        {((result && result.total_count > 0)|| loading) && (
          <>
            {pagination}
            {searchCount}
          </>
        )}
      </Grid>
      <Grid container spacing={3} style={{marginBottom: 24}}>
        {properties}
      </Grid>
      {((result && result?.total_count > 0) || loading) && (
        <Grid container direction="column" alignItems="center" style={{marginBottom: 12}}>
          {searchCount}
          {pagination}
        </Grid>
      )}
    </div>
  )
})

const toPropertySummaryProps = (unit: Unit): PropertySummaryProps => {
  const locales = strings.property_search_component.result;

  const building = unit.building
  if (!building) return {loading: true}

  const builtDate = building.built_date && new Date(building.built_date)

  return {
    name: `${building.name} ${unit.unit_name}`,
    address: building.address,
    imgUrl: building.cover_image_url,
    builtDate: builtDate && f(locales.builtDate, builtDate.getFullYear(), builtDate.getMonth() + 1),
    structure: building.structure && building.structure.replace('コンクリート', 'コン'), // TODO Fix
    floors: building.kaidate ? f(locales.kaidate, building.kaidate) : undefined,
    access: building.access,
    rent: unit.rent,
    cam: unit.cam,
    floor: unit.kaisuu,
    madori: unit.madori,
    area: unit.area_sqm,
    movableDate: unit.available_date_text,
    reikin: unit.reikin,
    shikikin: unit.shikikin,
    shikibiki: unit.shikibiki,
    hoshokin: unit.hoshokin,
    kyakuzuke: unit.reward_kyakuzuke,
    motozuke: unit.reward_motozuke,
    karinushi: unit.reward_karinushi,
    kashinushi: unit.reward_kashinushi,
    ad: unit.ad,
    pm: unit.pm_name,
    url: unit.url,
    details_page_path: unit.details_page_path
  }
}
