import {createContainer} from 'unstated-next'
import {useState} from 'react'
import {SearchResult} from './search_result'

const usePropertySearchForm = (initialState = {
  keyword: '',
  line: [] as string[],
  station: [] as string[],
  distance_in_minute: 30,
  rent: {
    from: {
      man: '',
      sen: ''
    },
    to: {
      man: '',
      sen: ''
    }
  },
  area: {
    from: '',
    to: ''
  },
  madori: {
    one_room: false,
    number_of_rooms: '1',
    number_of_rooms_to: '',
    k: false,
    dk: false,
    lk: false,
    ldk: false,
    s: false
  },
  ad: '0',
  immediately_available: false,
  no_application: false,
  built_year: '1983'
}) => {
  const [params, setParams] = useState(initialState)
  const [result, setResult] = useState<SearchResult>()
  const [loading, setLoading] = useState(false)

  return {params, setParams, result, loading, setLoading, setResult}
}
export const PropertySearchContainer = createContainer(usePropertySearchForm)
