import React, { useState, useEffect, useRef, KeyboardEvent } from 'react'
import ReactDOM from 'react-dom'
import { MDCTextField } from "@material/textfield"
import { MDCRipple } from '@material/ripple'
import { createContainer } from 'unstated-next'
import localeStrings from '../../locales/strings'
import { httpGet } from '../../utils'
import '../../styles/enrollment/submit.scss'
import { MDCLinearProgress } from '@material/linear-progress'

declare global {
  interface Window {
    locale: string,
    authenticityToken: string
  }
}

interface Office {
  license_id: number
  license_no_kbn: string
  license_number: string
  company_name: string
  index: number
  address: string
  phone: string
}

let progress: MDCLinearProgress

const useOffice = (initialState: Array<Office> = []) => {
  let [offices, setOffices] = useState(initialState)
  let fetchOffices = (licenseNumber: string) => {
    progress.open()
    httpGet(`/enrollment/search_offices?license_number=${licenseNumber}`).then((offices: Array<Office>) => {
      progress.close()
      setOffices(offices)
    })
  }
  let emptyOffices = () => {
    setOffices([])
  }
  return { offices, fetchOffices, emptyOffices }
}

const OfficeContainer = createContainer(useOffice)

const OfficeDisplay = () => {
  let office = OfficeContainer.useContainer()
  localeStrings.setLanguage(window.locale)

  const licenseNumberRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    document.querySelectorAll('.mdc-text-field').forEach(elm => {
      new MDCTextField(elm)
    })
    document.querySelectorAll('.mdc-button').forEach(elm => {
      new MDCRipple(elm)
    })
    document.querySelectorAll('.mdc-linear-progress').forEach(elm => {
      progress = new MDCLinearProgress(elm)
      progress.close()
    })
  }, [])

  const fetchOffices = () => {
    const licenseNumberInput = licenseNumberRef.current as HTMLInputElement
    if (licenseNumberInput !== null) {
      office.fetchOffices(licenseNumberInput.value)
    }
  }

  const onHandleLicenseNumberChanged = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      fetchOffices()
    } else {
      office.emptyOffices()
    }
  }

  const onSearchOfficeButtonClick = () => {
    fetchOffices()
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (office.offices.length == 0) {
      e.preventDefault()
    }
  }

  const offices = (
    <ol className="offices">
      {office.offices.map((office, index) => {
        const value = `license-${office.license_id}-${office.index}`
        return (
          <li key={value}>
            <input type="radio" name="license_id" value={value} defaultChecked={index == 0 ? true : false} id={value} />
            <label htmlFor={value}>
              {office.company_name}
              {office.license_no_kbn}
              {office.license_number}
              {office.address}
              {office.phone}
            </label>
          </li>
        )
      })}
    </ol>
  )

  const sendButton = office.offices.length == 0 ? null : (
    <button name="button" type="submit" className="mdc-button mdc-button--unelevated submit-button mdc-ripple-upgraded">
      <span className="mdc-button__label">{localeStrings.enrollment.submit.send}</span>
    </button>
  )

  return (
    <form action="/enrollment/receive" method="post" acceptCharset="UTF-8" onSubmit={onSubmit}>
      <input type="hidden" name="authenticity_token" value={window.authenticityToken} />
      <div className="form-inner">
        <div className="license-number-flex">
          <div>{localeStrings.enrollment.submit.licenseNumberPrefix}</div>
          <div className="mdc-text-field mdc-ripple-upgraded license-number-field">
            <input className="mdc-text-field__input" type="text" name="license_number" id="license_number" ref={licenseNumberRef} onKeyUp={onHandleLicenseNumberChanged} />
            <label className="mdc-floating-label" htmlFor="license_number">{localeStrings.enrollment.submit.licenseNumber}</label>
            <div className="mdc-line-ripple"></div>
          </div>
          <div>{localeStrings.enrollment.submit.licenseNumberSuffix}</div>
        </div>
        <button name="button" type="button" className="mdc-button mdc-button--outlined search-button mdc-ripple-upgraded" onClick={onSearchOfficeButtonClick}>
          <span className="mdc-button__label">{localeStrings.enrollment.submit.searchOffice}</span>
        </button>
        <div role="progressbar" className="mdc-linear-progress mdc-linear-progress--indeterminate">
          <div className="mdc-linear-progress__buffering-dots"></div>
          <div className="mdc-linear-progress__buffer"></div>
          <div className="mdc-linear-progress__bar mdc-linear-progress__primary-bar">
            <span className="mdc-linear-progress__bar-inner"></span>
          </div>
          <div className="mdc-linear-progress__bar mdc-linear-progress__secondary-bar">
            <span className="mdc-linear-progress__bar-inner"></span>
          </div>
        </div>
        {offices}
      </div>
      {sendButton}
    </form>
  )
}

const EnrollmentSubmit = () => {
  return (
    <OfficeContainer.Provider initialState={[]}>
      <OfficeDisplay />
    </OfficeContainer.Provider>
  )
}

export default () => {
  document.addEventListener('DOMContentLoaded', () => {
    ReactDOM.render(<EnrollmentSubmit />, document.getElementById('submit_form'))
  })
}
