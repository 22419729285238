import React from 'react'
import {Typography, Grid} from '@material-ui/core'
import {SelectableTextField} from './selectable_text_field'
import {FieldDescriptor} from '@shopify/react-form-state'
import FormState from '@shopify/react-form-state'

type AreaProps = {
  descriptor: FieldDescriptor<{from: string; to: string;}>
  strings: {
    label: string
    from: string
    to: string
    sqm: string
  }
}

export default React.memo(function Area({descriptor, strings}: AreaProps) {
  let areaOptions: string[] = []
  for (let i = 0; i < 10; i++) areaOptions.push(i * 5 + '')
  for (let i = 0; i < 5; i++) areaOptions.push(i * 10 + 50 + '')
  for (let i = 0; i < 9; i++) areaOptions.push(i * 25 + 100 + '')

  return (
    <FormState.Nested field={descriptor}>
      {({from, to}) => (<>
        <Typography align="left" variant="caption" component="div">{strings.label}</Typography>
        <Grid container spacing={1} alignItems="flex-end" wrap="nowrap">
          <Grid item>
            <i className="fas fa-ruler-combined"></i>
          </Grid>
          <Grid item style={{flex: '1 1 100%'}}>
            <SelectableTextField
              name="area[from]"
              descriptor={from}
              type="number"
              options={areaOptions}
              suffix={strings.sqm}
              inputProps={{ min: 0, style: { textAlign: 'right' } }}/>
          </Grid>
          <Grid item>
            <Typography>&#126;</Typography>
          </Grid>
          <Grid item style={{flex: '1 1 100%'}}>
            <SelectableTextField
              name="area[to]"
              descriptor={to}
              type="number"
              options={areaOptions}
              suffix={strings.sqm}
              inputProps={{ min: 0, style: { textAlign: 'right' } }}/>
          </Grid>
        </Grid>
      </>)}
    </FormState.Nested>
  )
}, (prevProps, nextProps) => prevProps.descriptor === nextProps.descriptor)

