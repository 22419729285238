import '../../styles/clients/edit'
import { MDCTextField } from '@material/textfield'
import strings from '../../locales/strings'

class SignInput {
  private form: HTMLFormElement
  private canvas: HTMLCanvasElement
  private canvasImage: HTMLImageElement
  private submitButton: HTMLInputElement
  private saveButton: HTMLButtonElement
  private clearButton: HTMLButtonElement

  private context: CanvasRenderingContext2D

  private flag: boolean = false

  private prevX: number = 0
  private prevY: number = 0
  private currX: number = 0
  private currY: number = 0

  private strokeStyle: string = 'black'
  private lineWidth: number = 2

  constructor(formId: string, canvasId: string, canvasImageId: string, submitButtonId: string, saveButtonId: string, clearButtonId: string) {
    this.form = document.getElementById(formId) as HTMLFormElement
    this.canvas = document.getElementById(canvasId) as HTMLCanvasElement
    this.canvasImage = document.getElementById(canvasImageId) as HTMLImageElement
    this.submitButton = document.getElementById(submitButtonId) as HTMLInputElement
    this.saveButton = document.getElementById(saveButtonId) as HTMLButtonElement
    this.clearButton = document.getElementById(clearButtonId) as HTMLButtonElement

    this.context = this.canvas.getContext('2d') as CanvasRenderingContext2D

    this.canvas.addEventListener('mousemove', (e) => {
      this.findxy('move', e)
    }, false)
    this.canvas.addEventListener('mousedown', (e) => {
      this.findxy('down', e)
    }, false)
    this.canvas.addEventListener('mouseup', (e) => {
      this.findxy('up', e)
    }, false)

    this.saveButton.addEventListener('click', (e) => {
      this.save(e)
    }, false)
    this.clearButton.addEventListener('click', (e) => {
      this.erase(e)
    }, false)
  }

  findxy(res: string, e: MouseEvent) {
    if (res === 'down') {
      this.prevX = this.currX
      this.prevY = this.currY
      this.currX = e.pageX - this.canvas.offsetLeft
      this.currY = e.pageY - this.canvas.offsetTop

      this.flag = true
    }
    if (res === 'up') {
      this.flag = false
    }
    if (res === 'move') {
      if (this.flag) {
        this.prevX = this.currX
        this.prevY = this.currY
        this.currX = e.pageX - this.canvas.offsetLeft
        this.currY = e.pageY - this.canvas.offsetTop
        this.draw()
      }
    }
  }

  draw() {
    this.canvasImage.style.display = 'none'

    this.context.beginPath()
    this.context.moveTo(this.prevX, this.prevY)
    this.context.lineTo(this.currX, this.currY)
    this.context.strokeStyle = this.strokeStyle
    this.context.lineWidth = this.lineWidth
    this.context.stroke()
    this.context.closePath()

    this.submitButton.disabled = true
    this.saveButton.classList.add('mdc-button--unelevated')
  }

  erase(e: MouseEvent) {
    e.preventDefault()
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
    this.canvasImage.style.display = 'none'
    this.canvas.style.display = 'inline'

    this.submitButton.disabled = false
    this.saveButton.classList.remove('mdc-button--unelevated')
    this.flag = false
  }

  save(e: MouseEvent) {
    e.preventDefault()
    const dataURL = this.canvas.toDataURL()
    this.canvasImage.src = dataURL
    this.canvasImage.style.display = 'inline'
    this.canvas.style.display = 'none'

    let imageInput = document.createElement('input')
    imageInput.type = 'hidden'
    imageInput.name = 'signature'
    imageInput.value = dataURL
    this.form.appendChild(imageInput)

    this.submitButton.disabled = false
    this.saveButton.classList.remove('mdc-button--unelevated')
    this.flag = false
  }
}

function getMDC<T>(el: HTMLElement) {
  for (const key of Object.getOwnPropertyNames(el)) {
    if (key.startsWith('MDC')) {
      return <T>(<any>el)[key]
    }
  }

  return null
}

class ElementHiddenByInputValue {
  private element: HTMLElement
  private readonly associatedInput: HTMLInputElement | HTMLSelectElement | null
  private readonly hidingValue: string | null
  private readonly hideMethod: 'cssDisplay'|'cssVisibility'|string

  constructor(element: HTMLElement,
              associatedInput: HTMLInputElement | HTMLSelectElement | null,
              hidingValue: string | null,
              hideMethod: 'cssDisplay'|'cssVisibility'|string) {
    this.element = element
    this.associatedInput = associatedInput
    this.hidingValue = hidingValue
    this.hideMethod = hideMethod

    if (this.associatedInput == null) {
      return
    }

    this.associatedInput.addEventListener('change', this.update.bind(this))
    this.update()
  }

  private update() {
    if (this.associatedInput == null) {
      return
    }

    if (this.hideMethod === 'cssDisplay') {
      this.element.style.display = this.mustHide() ? 'none' : ''
    } else {
      this.element.style.visibility = this.mustHide() ? 'hidden' : ''
    }
  }

  private mustHide() {
    if (!this.associatedInput) {
      return
    }

    const not = !!this.hidingValue && this.hidingValue[0] === '!'

    const expected = (this.hidingValue && this.hidingValue !== '0')
      ? not
        ? this.hidingValue.slice(1)
        : this.hidingValue
      : ''

    let result: boolean
    if (this.associatedInput instanceof HTMLInputElement && this.associatedInput.type === 'checkbox') {
      result = (this.associatedInput.checked ? this.associatedInput.value : undefined) === expected
    } else {
      result = this.associatedInput && this.associatedInput.value === expected
    }

    return +result ^ +not
  }
}

class InputOrMDC {
  private input: {value: string}
  private element: HTMLElement

  constructor(element: HTMLElement) {
    if (element instanceof HTMLInputElement) {
      this.input = element
    }

    const mdc = getMDC<MDCTextField>(element)
    if (!mdc) {
      throw Error('element is not input')
    }

    this.element = element
    this.input = mdc
  }

  value(val?: string) {
    if (val === undefined) {
      return this.input.value
    }

    return this.input.value = val
  }

  css(name: string, val?: string) {
    if (val === undefined) {
      return this.element.style[<any>name]
    }
    return this.element.style[<any>name] = val
  }

  addEventListener(type: string,
                   listener: EventListenerOrEventListenerObject,
                   options?: boolean | AddEventListenerOptions) {
    this.element.addEventListener(type, listener, options)
  }
}

class ZipCodeInput {
  private zipInput: InputOrMDC
  private associatedInputs: Map<string, InputOrMDC>
  private resolver: AddressResolver

  constructor(zipInput: InputOrMDC, associatedInputs: Map<string, InputOrMDC>) {
    this.zipInput = zipInput
    this.associatedInputs = associatedInputs
    this.resolver = new AddressResolver()

    if (!this.anyInputsHaveValues()) {
      this.hideAssociatedInputs()
    }

    zipInput.addEventListener('keyup', () => this.zipCodeChanged())
  }

  private async zipCodeChanged() {
    // TODO should lock
    const address = await this.resolver.resolve(this.zipInput.value())

    // this expression returns true if the type of the address variable is enum
    if (!(address instanceof Object)) {
      if (address === AddressResolverErrorCode.apiError) {
        this.showAssociatedInputs()
      }

      return
    }

    if (this.anyAssociatedInputsHaveValues()) {
      if (!this.confirmOverriding(address.fullAddress)) {
        return
      }
    }

    this.setValuesToAssociatedInputs(address)
    this.showAssociatedInputs()
  }

  private setValuesToAssociatedInputs(address: Address) {
    this.associatedInputs.forEach((input, key) => {
      if (address.hasOwnProperty(key)) {
        input.value((<any>address)[key])
      }
    })
  }

  private confirmOverriding(address: string) {
    const message = strings.formatString(strings.client.edit.override_confirmation, address).toString()
    return confirm(message)
  }

  private anyInputsHaveValues() {
    return !!this.zipInput.value() || this.anyAssociatedInputsHaveValues()
  }

  private anyAssociatedInputsHaveValues() {
    return !!Array.from(this.associatedInputs.values()).filter(el => el.value()).length
  }

  private showAssociatedInputs() {
    this.associatedInputs.forEach(input => input.css('display', ''))
  }

  private hideAssociatedInputs() {
    this.associatedInputs.forEach(input => input.css('display', 'none'))
  }
}

interface Address {
  pref: string
  city: string
  town: string
  address: string
  fullAddress: string
}

enum AddressResolverErrorCode {
  invalidZipCode,
  ZipCodeUnchanged,
  apiError
}

class AddressResolver {
  private latest: string = ''

  async resolve(str: string): Promise<Address | AddressResolverErrorCode> {
    if (!this.isZip(str)) {
      return AddressResolverErrorCode.invalidZipCode
    }

    const zip = this.normalizeZip(str)
    if (zip === this.latest) {
      return AddressResolverErrorCode.ZipCodeUnchanged
    }

    const address = await this.getAddressFromZip(zip)
    if (address === null) {
      return AddressResolverErrorCode.apiError
    }

    this.latest = zip
    return address
  }

  private async getAddressFromZip(zip: string): Promise<Address | null> {
    const response: any = await (await fetch(`https://api.zipaddress.net/?zipcode=${zip}`)).json()
    if (response.code !== 200) {
      return null
    }

    return response.data
  }

  private isZip(str: string) {
    return str.match(/^[0-9０-９]{3}[-－]?[0-9０-９]{4}$/)
  }

  private normalizeZip(zip: string) {
    return zip
      .replace(/[０-９]/g, c => String.fromCharCode(c.charCodeAt(0) - 65248))
      .replace(/[-－]/, '')
  }
}

export default () => {
  document.addEventListener('DOMContentLoaded', () => {
    new SignInput('client-form', 'signature-canvas', 'canvas-image', 'submit-button', 'save-button', 'clear-button');


    // behavior of the '入力する' button of the housemates section
    const addButton = document.querySelector<HTMLButtonElement>('.js-show-more-housemate')
    if (addButton) {
      addButton.addEventListener('click', e => {
        const housemateFieldsEl = document.querySelector<HTMLElement>('.housemate-fields.hidden')
        if (housemateFieldsEl) {
          housemateFieldsEl.style.display = ''
          housemateFieldsEl.classList.remove('hidden')
        }

        if (!document.querySelector('.housemate-fields.hidden')) {
          addButton.style.display = 'none'
        }
      })
    }

    document.querySelectorAll<HTMLElement>('.housemate-fields').forEach(el => {
      if (!Array.from(el.querySelectorAll<HTMLInputElement | HTMLSelectElement>('input,select'))
        .filter(el => el.value || el.value === '0').length) {
        el.style.display = 'none'
        el.classList.add('hidden')
      }
    })


    document.querySelectorAll('.js-hide-by-input').forEach(el => {
      if (el instanceof HTMLElement) {
        const associatedInput = document.querySelector<HTMLInputElement>(el.dataset.hideByInputSelector || '')
        new ElementHiddenByInputValue(el, associatedInput, el.dataset.hideByInputValue || null, el.dataset.hideByInputMethod || 'cssVisibility')
      }
    })


    document.querySelectorAll('.js-zip-input').forEach(el => {
      let otherElements: Map<string, InputOrMDC> = new Map()
      if (el instanceof HTMLElement) {
        for (const key in el.dataset) {
          const m = key.match(/zipInput(Pref|City|Town|Address|FullAddress|Other)Input/)
          if (!m) {
            continue
          }

          const element = document.querySelector<HTMLElement>(el.dataset[key] || '')
          if (!element) {
            continue
          }
          otherElements.set(m[1].toLowerCase(), new InputOrMDC(element))
        }

        new ZipCodeInput(new InputOrMDC(el), otherElements)
      }
    })
  })
}
