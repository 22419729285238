import React from 'react'
import {Grid, Hidden, makeStyles, Typography} from '@material-ui/core'
import {LabelType, SelectableTextField} from './selectable_text_field'
import {FieldDescriptor} from '@shopify/react-form-state'
import FormState from '@shopify/react-form-state'

type Strings = {
  label: string,
  man: string,
  yen: string,
  from: {
    man: string
    sen: string
  }
  to: {
    man: string
    sen: string
  }
}

let manOptions: string[] = []
for (let i = 0; i < 30; i++) manOptions.push(i + '')
for (let i = 0; i < 14; i++) manOptions.push(i * 5 + 30 + '')
for (let i = 0; i < 9; i++) manOptions.push(i * 25 + 100 + '')

let senOptions: string[] = []
for (let i = 0; i < 10; i++) senOptions.push(i * 1000 + '')

type CurrencyTextFieldProps = {
  label: string
  currencyType: 'man' | 'sen',
  strings: Strings
  descriptor: FieldDescriptor<string>
  name: string
}

const CurrencyTextField = React.memo((props: CurrencyTextFieldProps) => {
  const { label, currencyType, strings, descriptor, name } = props
  let options: string[], suffix: string, max: number | undefined

  if (currencyType == 'man') {
    options = manOptions
    suffix = strings.man
    max = undefined
  } else {
    options = senOptions
    suffix = strings.yen
    max = 9999
  }

  return (
    <SelectableTextField
      name={name}
      descriptor={descriptor}
      type="number"
      options={options}
      label={label}
      labelType={LabelType.aria}
      suffix={suffix}
      inputProps={{ min: 1, max: max, style: { textAlign: 'right' } }}/>
  )
}, (prevProps, nextProps) => prevProps.descriptor === nextProps.descriptor)

const useStyles = makeStyles({
  fieldItem: {
    flex: '1 1 100%'
  }
})

type RentProps = {
  strings: Strings,
  narrow?: boolean
  descriptor: FieldDescriptor<{ from: { man: string; sen: string; }; to: { man: string; sen: string; }; }>
}

export default React.memo(function Rent(props: RentProps) {
  const classes = useStyles()
  const {narrow} = props

  return (
    <FormState.Nested field={props.descriptor}>
      {({from, to}) => (
        <>
          <Typography align="left" variant="caption" component="div">{props.strings.label}</Typography>
          <Grid container spacing={1} alignItems="flex-end" wrap="nowrap">
            <FormState.Nested field={from}>
              {({man, sen}) => {
                return (
                  <>
                    <Grid item>
                      <i className="fas fa-yen-sign"></i>
                    </Grid>
                    <Grid item className={classes.fieldItem}>
                      <CurrencyTextField
                        name="rent[from][man]"
                        descriptor={man}
                        currencyType="man"
                        strings={props.strings}
                        label={props.strings.from.man}/>
                    </Grid>
                    <Hidden xsDown xsUp={narrow}>
                      <Grid item className={classes.fieldItem}>
                        <CurrencyTextField
                          name="rent[from][sen]"
                          descriptor={sen}
                          currencyType="sen"
                          strings={props.strings}
                          label={props.strings.from.sen}/>
                      </Grid>
                    </Hidden>
                  </>
                )
              }}
            </FormState.Nested>
            <Grid item>
              <Typography>&#126;</Typography>
            </Grid>
            <FormState.Nested field={to}>
              {({man, sen}) => {
                return (
                  <>
                    <Grid item className={classes.fieldItem}>
                      <CurrencyTextField
                        name="rent[to][man]"
                        descriptor={man}
                        currencyType="man"
                        strings={props.strings}
                        label={props.strings.to.man}/>
                    </Grid>
                    <Hidden xsDown xsUp={narrow}>
                      <Grid item className={classes.fieldItem}>
                        <CurrencyTextField
                          name="rent[to][sen]"
                          descriptor={sen}
                          currencyType="sen"
                          strings={props.strings}
                          label={props.strings.to.sen}/>
                      </Grid>
                    </Hidden>
                  </>
                )
              }}
            </FormState.Nested>
          </Grid>
        </>
      )}
    </FormState.Nested>
  )
}, (prevProps, nextProps) => prevProps.descriptor === nextProps.descriptor && prevProps.narrow === nextProps.narrow)
