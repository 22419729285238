import {InputProps as StandardInputProps} from '@material-ui/core/Input/Input'
import {Autocomplete} from '@material-ui/lab'
import {InputAdornment, TextField} from '@material-ui/core'
import React from 'react'
import {FieldDescriptor} from '@shopify/react-form-state'

export enum LabelType {
  normal,
  shrink,
  aria,
}

interface SelectableTextFieldProps {
  options: string[]
  label?: string
  type?: string
  suffix?: string
  labelType?: LabelType
  inputProps?: StandardInputProps['inputProps']
  value?: string
  descriptor: FieldDescriptor<any>
  name?: string
}

export const SelectableTextField = (props: SelectableTextFieldProps) => {
  const labelType = props.labelType || LabelType.normal
  const {descriptor, name} = props

  return (
    <Autocomplete
      options={props.options}
      getOptionLabel={(option: number) => option.toString()}
      freeSolo
      value={descriptor.value}
      defaultValue={descriptor.initialValue}
      onInputChange={(event, value) => {
        descriptor.onChange(value)
      }}
      onChange={(event, value) => {
        descriptor.onChange(value)
      }}
      onBlur={_ => descriptor?.onBlur()}
      renderInput={params => {
        const endAdornment = props.suffix ? (<InputAdornment position="end">{props.suffix}</InputAdornment>) : undefined

        return (<TextField
            {...params}
            name={name || descriptor.name}
            label={labelType != LabelType.aria && props.label}
            type={props.type}
            fullWidth
            InputLabelProps={{shrink: labelType == LabelType.shrink || undefined}}
            inputProps={{
              ...params.inputProps,
              ...props.inputProps,
              'aria-label': labelType == LabelType.aria ? props.label : undefined,
            }}
            InputProps={{
              ...params.InputProps,
              style: { paddingRight: 0 },
              endAdornment: (
                <React.Fragment>
                  {endAdornment}
                  {params.InputProps.endAdornment}
                </React.Fragment>)
            }}/>
        )}}
    />)
}
