import React, {CSSProperties} from 'react'
import {
  Checkbox, FormControlLabel, FormGroup, Grid,
  Hidden, makeStyles, Theme, Typography, useMediaQuery, useTheme,
} from '@material-ui/core'
import {LabelType, SelectableTextField} from './selectable_text_field'
import {FieldDescriptor} from '@shopify/react-form-state'
import FormState from '@shopify/react-form-state'

type MadoriCheckboxProps = {
  id: string
  label: React.ReactNode
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom'
  style?: CSSProperties
  disabled?: boolean
  descriptor: FieldDescriptor<boolean>
  name: string
}

const MadoriCheckbox = React.memo((props: MadoriCheckboxProps) => {
  const {descriptor, name} = props

  return (
    <FormControlLabel
      style={props.style}
      labelPlacement={props.labelPlacement}
      disabled={props.disabled}
      control={
        <Checkbox
          id={props.id}
          value="on"
          checked={descriptor.value}
          onChange={(event, checked) => descriptor.onChange(checked)}
          onBlur={() => descriptor.onBlur()}
          name={name}/>}
      value="on"
      label={props.label}/>
  )
}, (prevProps, nextProps) => prevProps.descriptor === nextProps.descriptor && prevProps.disabled === nextProps.disabled)

type StyleProps = {
  theme: Theme,
  narrow?: boolean
}

const generateNarrowStyles = (styles: {}, narrowStyles: {}, props: StyleProps) => {
  if (props.narrow) {
    return Object.assign({}, styles, narrowStyles)
  }
  return Object.assign({}, styles, {
    [props.theme.breakpoints.down('xs')]: {
      narrowStyles
    }})
}

const useStyles = makeStyles({
  root: (props: StyleProps) =>
    generateNarrowStyles({
        border: 'thin solid #ccc',
        borderRadius: 5,
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 4,
        paddingBottom: 12
      }, {
        paddingLeft: 6,
        paddingRight: 6,
        paddingTop: 4,
        paddingBottom: 6
      },
      props
    ),
  container: (props: StyleProps) => (props.narrow
      ? { flexDirection: 'column' }
      : { [props.theme.breakpoints.down('xs')]: {
          flexDirection: 'column'
        }
      }
  ),
  symbolItem: {
    flex: '0 1 auto'
  },
  checkboxItem: {
    flex: '0 1 auto'
  },
  inputs: {
    flex: '0 1 auto'
  },
  input: {
    flex: '0 1 100%',
    minWidth: 100
  },
  plans: (props: StyleProps) => generateNarrowStyles({}, {justifyContent: 'center'}, props),
  roomNumbers: (props: StyleProps) =>
    generateNarrowStyles({
        flex: '1 1 100%',
        marginTop: -18,
      }, {
        marginBottom: 12
      },
      props
    ),
  note: (props: StyleProps) => generateNarrowStyles({}, {textAlign: 'center'}, props)
})

type MadoriProps = {
  labels: {
    madori: string
    numberOfRooms: string
    serviceRoom: string
    noteSymbol: string
  },
  narrow?: boolean
  descriptor: FieldDescriptor<{one_room: boolean; number_of_rooms: string; number_of_rooms_to: string; k: boolean; dk: boolean; lk: boolean; ldk: boolean; s: boolean}>
}

const roomNumberOptions = ['1', '2', '3', '4', '5', '6']

export default React.memo(function Madori(props: MadoriProps) {
  const theme = useTheme()
  const { narrow, descriptor } = props
  const classes = useStyles({theme, narrow})
  const downXs = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <div className={classes.root}>
      <Typography variant="caption" component="div">{props.labels.madori}</Typography>
      <FormState.Nested field={descriptor}>
        {(fields) => {
          const {one_room, number_of_rooms, number_of_rooms_to, k, dk, lk, ldk, s} = fields

          return (
          <Grid container
                className={classes.container}
                alignItems="center"
                wrap="nowrap"
                spacing={downXs || narrow ? 1 : 2}>
            <Grid item
                  className={classes.inputs}>
              <Typography align="left"
                          variant="caption"
                          component="div"
                          style={{opacity: .6}}>
                {props.labels.numberOfRooms}
              </Typography>
              <Grid container wrap="nowrap" spacing={1}>
                <Grid item className={classes.input}>
                  <SelectableTextField
                    name="madori[number_of_rooms]"
                    descriptor={number_of_rooms}
                    type="number"
                    options={roomNumberOptions}
                    label={props.labels.numberOfRooms}
                    labelType={LabelType.aria}
                    inputProps={{ min: 1, style: { textAlign: 'right' } }}/>
                </Grid>
                <Grid item className={classes.symbolItem}>
                  <Typography>&#126;</Typography>
                </Grid>
                <Grid item className={classes.input}>
                  <SelectableTextField
                    name="madori[number_of_rooms_to]"
                    descriptor={number_of_rooms_to}
                    type="number"
                    options={roomNumberOptions}
                    label={props.labels.numberOfRooms}
                    labelType={LabelType.aria}
                    inputProps={{ min: 1, style: { textAlign: 'right' } }}/>
                </Grid>
              </Grid>
            </Grid>
            <Hidden smUp={!narrow}>
              <Grid item className={classes.symbolItem}>
                &#43;
              </Grid>
            </Hidden>
            <Grid item className={classes.checkboxItem}>
              <FormGroup row={downXs || narrow} className={classes.plans}>
                <MadoriCheckbox
                  name="madori[one_room]"
                  descriptor={one_room}
                  id="madori_1r"
                  label="R"
                  disabled={parseInt(number_of_rooms.value) > 1}/>
                <MadoriCheckbox
                  name="madori[k]"
                  descriptor={k}
                  id="madori_k"
                  label="K"/>
                <MadoriCheckbox
                  name="madori[dk]"
                  descriptor={dk}
                  id="madori_dk"
                  label="DK"/>
                <MadoriCheckbox
                  name="madori[lk]"
                  descriptor={lk}
                  id="madori_lk"
                  label="LK"/>
                <MadoriCheckbox
                  name="madori[ldk]"
                  descriptor={ldk}
                  id="madori_ldk"
                  style={{marginRight: 0}}
                  label="LDK"/>
              </FormGroup>
            </Grid>
            <Grid item className={classes.symbolItem}>
              &#43;
            </Grid>
            <Grid item className={classes.checkboxItem}>
              <MadoriCheckbox
                name="madori[s]"
                descriptor={s}
                id="madori_s"
                label={(<>S<small>{props.labels.noteSymbol}</small></>)}/>
            </Grid>
          </Grid>
        )}}
      </FormState.Nested>
      <div className={classes.note}>
        <small>{props.labels.noteSymbol}{props.labels.serviceRoom}</small>
      </div>
    </div>
  )
}, ((prevProps, nextProps) => prevProps.descriptor === nextProps.descriptor && prevProps.narrow === nextProps.narrow))
