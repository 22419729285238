import '../../styles/properties/show'

export default () => {
  const unitBox = <HTMLDivElement>document.querySelector('.unit-box'),
    buildingBox = <HTMLDivElement>document.querySelector('.building-box');

  (<HTMLButtonElement>document.querySelector('.js-show-unit'))
    .addEventListener('click', () => {
      buildingBox.style.display = 'none'
      unitBox.style.display = ''
    });

  (<HTMLButtonElement>document.querySelector('.js-show-building'))
    .addEventListener('click', () => {
      unitBox.style.display = 'none'
      buildingBox.style.display = ''
    })
}
