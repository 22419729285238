import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Skeleton from '@material-ui/lab/Skeleton'
import Avatar from '@material-ui/core/Avatar'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import {red} from '@material-ui/core/colors'
import React from 'react'
import CardActions from '@material-ui/core/CardActions'
import IconButton from '@material-ui/core/IconButton'
import {Color, Theme} from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import createStyles from '@material-ui/core/styles/createStyles'
import grey from '@material-ui/core/colors/grey'
import strings, {f} from '../../../../locales/strings'

export type PropertySummaryProps = {
  loading?: boolean,
  name?: string,
  address?: string,
  builtDate?: string,
  structure?: string,
  floors?: string,
  access?: string,
  rent?: number,
  cam?: number,
  reikin?: number
  shikikin?: number
  shikibiki?: number
  hoshokin?: number
  ad?: number
  kashinushi?: number
  motozuke?: number
  karinushi?: number
  kyakuzuke?: number
  floor?: number
  madori?: string
  area?: number
  movableDate?: string
  imgUrl?: string
  pm?: string
  url?: string
  details_page_path?: string
}

const numberToCurrency = (num: number) => {
  return num.toLocaleString(navigator.language, {minimumFractionDigits: 0})
}

const useCellStyles = makeStyles(
  {
    root: {},
    inner: {
      background: (props: CellProps) => {
        const color: any = props.color || grey
        const index = props.colorIndex || 300
        return color[index]
      },
      color: (props: CellProps) => {
        const index = props.colorIndex || 300
        return index >= 500 ? '#fff' : '#000'
      },
      fontSize: 13,
      textAlign: 'center',
      opacity: (props: CellProps) => props.disabled ? .5 : 1,
    },
    header: {
      paddingTop: 2,
      paddingBottom: 2,
      display: 'block',
      fontSize: 10,
      background: '#fff',
      color: '#000',
      opacity: .7,
      marginBottom: 2,
    },
  },
)

type CellProps = {
  header: string,
  value: string,
  disabled?: boolean,
  color?: Color,
  colorIndex?: number,
  loading?: boolean
}

const Cell = (props: CellProps) => {
  const classes = useCellStyles(props)

  return (
    <Grid item xs={3} className={classes.root}>
      {props.loading ? (
        <Skeleton variant="rect" height={40}></Skeleton>
      ) : (
        <div className={classes.inner}>
          <span className={classes.header}>{props.header}</span>
          {props.value}
        </div>
      )}
    </Grid>
  )
}

const usePropertySummaryStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      position: 'relative',
    },
    cardHeader: {
      '& .MuiCardHeader-content': {
        overflow: 'hidden',
      },
      '& .MuiCardHeader-title': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '& .MuiCardHeader-subheader': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
      position: 'relative',
    },
    avatar: {
      backgroundColor: grey[600],
    },
    buildingBar: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      paddingTop: 4,
      paddingBottom: 4,
      paddingLeft: 8,
      paddingRight: 8,
      background: 'rgba(0, 0, 0, .5)',
      color: '#fff',
    },
    items: {
      marginTop: 4,
    },
    listingFee: {
      position: 'absolute',
      bottom: 12,
      right: 16,
      color: (props: PropertySummaryProps) => {
        return (props.ad || 0) > 0 ? red[500] : grey[500]
      },
      fontWeight: 700,
      fontSize: 20,
      textDecoration: (props: PropertySummaryProps) => {
        return (props.ad || 0) >= 200 ? 'underline' : 'none'
      },
      '& > span': {
        fontSize: 16,
        marginRight: 4,
      },
    },
  }),
)

export const PropertySummary = (props: PropertySummaryProps) => {
  const locales = strings.property_search_component.summary
  const classes = usePropertySummaryStyles(props)
  const {
    loading, name, address, builtDate, structure, floors, access, rent,
    cam, floor, madori, area, reikin, shikikin, shikibiki, hoshokin, ad,
    kyakuzuke, motozuke, kashinushi, karinushi, movableDate, imgUrl, pm,
    url, details_page_path
  } = props

  const fixedCost = (reikin || 0) + (shikibiki || 0)
  const deposit = (shikikin || 0) + (hoshokin || 0)

  return (
    <Card className={classes.card}>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          loading ? (
            <Skeleton variant="circle" width={40} height={40}/>
          ) : (
            <Avatar className={classes.avatar}><i className="fa fa-building"></i></Avatar>
          )
        }
        title={loading ? <Skeleton height={10} width="70%" style={{marginBottom: 6}}/> : name || locales.unknownName}
        subheader={loading ? <Skeleton height={10} width="80%" style={{marginBottom: 6}}/> : address || locales.unknownAddress}
      >
      </CardHeader>
      {(loading || !imgUrl) ? (
        <Skeleton variant="rect" className={classes.media}/>
      ) : (
        <CardMedia
          className={classes.media}
          image={process.env.NODE_ENV && process.env.NODE_ENV === 'development' ? 'https://via.placeholder.com/150' : imgUrl}
          children={
            <div className={classes.buildingBar}>
              <Typography
                variant="body2">{builtDate || locales.unknownBuiltDate} / {structure || locales.unknownStructure} / {floors || locales.unknownFloors}<br/>{access || locales.unknownAccess}
              </Typography>
            </div>
          }
        />
      )}
      <CardContent>
        {loading ? (
          <Skeleton height={10} width="90%" style={{marginBottom: 14}}/>
        ) : (
          <Typography variant="body2" color="textSecondary" component="p">
            {rent ? (f(locales.rent, numberToCurrency(rent + (cam || 0))) + (cam ? f(locales.cam, numberToCurrency(cam || 0)) : '')) : locales.unknownRent}
          </Typography>
        )}
        <Grid container spacing={1} className={classes.items}>
          <Cell header={locales.floor}
                value={floor ? f(locales.floorValue, floor) : locales.unknown}
                disabled={!floor}
                loading={loading}/>
          <Cell header={locales.madori}
                value={madori || locales.unknown}
                disabled={!madori}
                loading={loading}/>
          <Cell header={locales.area}
                value={area ? f(locales.sqm, area) : locales.unknown}
                disabled={!area}
                loading={loading}/>
          <Cell header={locales.movableDate}
                value={movableDate || locales.unknown}
                disabled={!movableDate}
                color={movableDate === locales.immediately ? red : undefined}
                colorIndex={movableDate === locales.immediately ? 500 : undefined}
                loading={loading}/>
          <Cell header={locales.fixedCost}
                value={fixedCost > 0 ? f(locales.months, fixedCost) : locales.none}
                color={fixedCost === 0 ? red : undefined}
                colorIndex={fixedCost === 0 ? 500 : undefined}
                loading={loading}/>
          <Cell header={locales.deposit}
                value={deposit > 0 ? f(locales.months, deposit) : locales.none}
                color={deposit === 0 ? red : undefined}
                colorIndex={deposit === 0 ? 500 : undefined}
                loading={loading}/>
          <Cell header={locales.kyakuzuke}
                value={kyakuzuke ? f(locales.percent, kyakuzuke.toString()) : locales.unknown}
                loading={loading}/>
          <Cell header={locales.karinushi}
                value={karinushi ? f(locales.percent, karinushi.toString()) : locales.unknown}
                loading={loading}/>
        </Grid>
        <div style={{marginTop: 12, marginBottom: -36, textAlign: 'right'}}>
          {loading ? (
            <Skeleton height={10} width="90%" style={{marginBottom: 14}}/>
          ) : (
            <Typography variant="body2" color="textSecondary" component="p">
              {pm}
            </Typography>
          )}
        </div>
        <div className={classes.listingFee}>
          {loading ? (
            <Skeleton width={80}/>
          ) : (
            <React.Fragment>
              <span>{locales.ad}</span>{ad ? strings.formatString(locales.percent, ad) : locales.none}
            </React.Fragment>
          )}
        </div>
      </CardContent>
      <CardActions disableSpacing>
        {loading ? (
          <React.Fragment>
            <Skeleton variant="circle" width={32} height={32} style={{margin: 8}}/>
            <Skeleton variant="circle" width={32} height={32} style={{margin: 8}}/>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <a href={details_page_path}>
              <IconButton><i className="fas fa-info-circle"></i></IconButton>
            </a>
            <a href={url} target="_blank" rel="noopener">
              <IconButton><i className="fas fa-external-link-alt"></i></IconButton>
            </a>
          </React.Fragment>
        )}
      </CardActions>
    </Card>
  )
}

