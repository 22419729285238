import {httpGet} from '../../../utils'

export async function fetchProperties(pageNumber = 1) {
  const searchFormElement = document.querySelector<HTMLFormElement>('#search-form')
  if (!searchFormElement) return

  let queryParams = ''
  new FormData(searchFormElement).forEach((value, key) => {
    queryParams += `&${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`
  })

  queryParams += `&page=${pageNumber}`

  return {
    properties: await httpGet(`/api/properties.json?${queryParams}`),
    queryParams: queryParams
  }
}