import homeIndex from '../pages/home/index'
import homeTos from '../pages/home/tos'
import homePrivacy from '../pages/home/privacy'
import agentsNew from '../pages/agents/new'
import agentsEdit from '../pages/agents/edit'
import agentsShow from '../pages/agents/show'
import agentSessionsNew from '../pages/agent_sessions/new'
import dashboardIndex from '../pages/dashboard/index'
import propertiesIndex from '../pages/properties/index'
import propertiesShow from '../pages/properties/show'
import enrollmentSubmit from '../pages/enrollment/submit'
import enrollmentVerify from '../pages/enrollment/verify'
import viewingsIndex from '../pages/viewings/index'
import viewingsShow from '../pages/viewings/show'
import viewingsNew from '../pages/viewings/new'
import viewingsEdit from '../pages/viewings/edit'
import moveInApplicationsIndex from '../pages/move_in_applications/index'
import moveInApplicationsNew from '../pages/move_in_applications/new'
import moveInApplicationsShow from '../pages/move_in_applications/show'
import moveInApplicationsEdit from '../pages/move_in_applications/edit'
import clientsIndex from '../pages/clients/index'
import clientsShow from '../pages/clients/show'
import clientsEdit from '../pages/clients/edit'
import clientsNew from '../pages/clients/new'
import passwordResetsNew from '../pages/password_resets/new'
import passwordResetsEdit from '../pages/password_resets/edit'

import mdcAutoInit, { MDCAttachable } from '@material/auto-init'
import { MDCTextField } from "@material/textfield"
import { MDCRipple } from "@material/ripple"
import { MDCTopAppBar } from "@material/top-app-bar"
import { MDCCheckbox } from "@material/checkbox"
import { MDCFormField } from "@material/form-field"
import { MDCSnackbar } from "@material/snackbar"
import { MDCDataTable } from "@material/data-table"
import { MDCSelect } from "@material/select"
import { MDCDialog } from "@material/dialog"
import { MDCDrawer } from "@material/drawer"
import { MDCList } from "@material/list"
import { MDCTabBar } from "@material/tab-bar"

import '../styles/vendor.scss'
import '../styles/common.scss'

if (document.querySelector('body.home-index')) {
  homeIndex()
}
if (document.querySelector('body.home-tos')) {
  homeTos()
}
if (document.querySelector('body.home-privacy')) {
  homePrivacy()
}
if (document.querySelector('body.agents-new')) {
  agentsNew()
}
if (document.querySelector('body.agents-edit')) {
  agentsEdit()
}
if (document.querySelector('body.agents-show')) {
  agentsShow()
}
if (document.querySelector('body.agent_sessions-new')) {
  agentSessionsNew()
}
if (document.querySelector('body.dashboard-index')) {
  dashboardIndex()
}
if (document.querySelector('body.properties-index')) {
  propertiesIndex()
}
if (document.querySelector('body.properties-show')) {
  propertiesShow()
}
if (document.querySelector('body.enrollment-submit')) {
  enrollmentSubmit()
}
if (document.querySelector('body.enrollment-verify')) {
  enrollmentVerify()
}
if (document.querySelector('body.viewings-index')) {
  viewingsIndex()
}
if (document.querySelector('body.viewings-show')) {
  viewingsShow()
}
if (document.querySelector('body.viewings-new')) {
  viewingsNew()
}
if (document.querySelector('body.viewings-edit')) {
  viewingsEdit()
}
if (document.querySelector('body.move_in_applications-index')) {
  moveInApplicationsIndex()
}
if (document.querySelector('body.move_in_applications-show')) {
  moveInApplicationsShow()
}
if (document.querySelector('body.move_in_applications-new')) {
  moveInApplicationsNew()
}
if (document.querySelector('body.move_in_applications-edit')) {
  moveInApplicationsEdit()
}
if (document.querySelector('body.clients-show')) {
  clientsShow()
}
if (document.querySelector('body.clients-edit')) {
  clientsEdit()
}
if (document.querySelector('body.clients-index')) {
  clientsIndex()
}
if (document.querySelector('body.clients-new')) {
  clientsNew()
}
if (document.querySelector('body.password_resets-new')) {
  passwordResetsNew()
}
if (document.querySelector('body.password_resets-edit')) {
  passwordResetsEdit()
}

mdcAutoInit.register('MDCTextField', <MDCAttachable>MDCTextField)
mdcAutoInit.register('MDCRipple', <MDCAttachable>MDCRipple)
mdcAutoInit.register('MDCTopAppBar', <MDCAttachable>MDCTopAppBar)
mdcAutoInit.register('MDCCheckbox', <MDCAttachable>MDCCheckbox)
mdcAutoInit.register('MDCFormField', <MDCAttachable>MDCFormField)
mdcAutoInit.register('MDCSnackbar', <MDCAttachable>MDCSnackbar)
mdcAutoInit.register('MDCDataTable', <MDCAttachable>MDCDataTable)
mdcAutoInit.register('MDCSelect', <MDCAttachable>MDCSelect)
mdcAutoInit.register('MDCDialog', <MDCAttachable>MDCDialog)
mdcAutoInit.register('MDCDrawer', <MDCAttachable>MDCDrawer)
mdcAutoInit.register('MDCList', <MDCAttachable>MDCList)
mdcAutoInit.register('MDCTabBar', <MDCAttachable>MDCTabBar)
mdcAutoInit()

document.querySelectorAll('.ctb-snackbar.ctb-snackbar--auto-open')
  .forEach(elm => (<any>elm).MDCSnackbar.open())

document.querySelectorAll('.ctb-snackbar__dismiss')
  .forEach(elm => {
    elm.addEventListener('click', () => {
      const snackbar = (<any>elm.closest('.ctb-snackbar')).MDCSnackbar
      snackbar.close()
    })
  })

if (document.getElementById('page-menu')) {
  const menuDrawer = (<any>document.getElementById('page-menu')).MDCDrawer

  document.querySelectorAll('.js-menu-opener')
    .forEach(elm => {
      elm.addEventListener('click', () => {
        menuDrawer.open = true
      })
    });

  const menuListEl: any = document.querySelector('#page-menu .mdc-list')
  const menuList = menuListEl.MDCList
  const mainContentEl: any = document.querySelector('.ctb-main')
  menuList.wrapFocus = true
  menuDrawer.list_.singleSelection = true

  menuListEl.addEventListener('click', () => {
    menuDrawer.open = false
  })

  document.body.addEventListener('MDCDrawer:closed', () => {
    mainContentEl.querySelector('input, button').focus()
  })
}
