import React from 'react'
import _debounce from 'lodash/debounce'
import { httpGet } from '../../../../utils'
import AutocompleteTextField from './autocomplete_text_field'
import {InputAdornment} from '@material-ui/core'
import {FieldDescriptor} from '@shopify/react-form-state'

type KeywordProps = {
  descriptor: FieldDescriptor<string>
  labels: { keyword: string }
}

interface KeywordResult {
  key: string
  name: string
}

interface KeywordSection {
  title: string
  suggestions: Array<KeywordResult>
}

export default React.memo(function Keyword({descriptor, labels}: KeywordProps) {
  const fetchOptions = (setter: (newOptions: any[]) => void, inputValue?: string) => {
    _debounce(async (word) => {
      const data: KeywordSection[] = await httpGet(`/api/keywords.json?word=${word}`)
      const options = data.reduce((acr: any[], current) =>
        acr.concat(current.suggestions.map((item: KeywordResult) =>
          ({key: item.key, name: item.name, group: current.title}))), [])
      setter(options)
    }, 300)(inputValue)
  }

  return (
    <AutocompleteTextField
      name="keyword"
      descriptor={descriptor}
      getOptionLabel={option => typeof(option) === 'string' ? option : option.name}
      setOptionsOn="input"
      setOptions={fetchOptions}
      groupBy={option => option.group}
      textFieldProps={{
        label: labels.keyword,
        InputProps: {
          startAdornment: (
            <InputAdornment position="start">
              <i className="material-icons">business</i>
            </InputAdornment>
          )
        }
      }}
      renderOption={option => {
        return <div key={option.id}>{option.name}</div>
      }}
      freeSolo
    />
  )
}, ((prevProps, nextProps) => prevProps.descriptor === nextProps.descriptor))
