import fetch from 'isomorphic-fetch'

interface ResponseData {
  status: number
  statusText: string
  json: () => any
}

const defaultHeaders: { [key: string]: string } = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}

const buildHeaders = (paramHeaders: { [key: string]: string } = {}) => {
  let headers: { [key: string]: string } = {}

  for (const key in defaultHeaders) {
    headers[key] = defaultHeaders[key]
  }
  for (const key in paramHeaders) {
    headers[key] = paramHeaders[key]
  }

  return headers
}

const checkStatus = (response: ResponseData) => {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else {
    throw new Error(response.statusText)
  }
}

export async function httpGet(url: string, headers = {}) {
  return fetch(url, {
    credentials: 'include',
    headers: buildHeaders(headers)
  }).then(checkStatus).then(response => response.json())
}

export async function httpPost(url: string, data = {}, method = 'POST', headers = {}) {
  const body = JSON.stringify(data)

  return fetch(url, {
    credentials: 'include',
    method: method,
    headers: buildHeaders(headers),
    body: body
  }).then(checkStatus).then(response => response.json())
}
